import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHandlerService } from './http-handler.service';
import { environment } from './../../environments/environment';
import { AppTokenService } from './app-token.service';
import { CarouselResponse } from '../models/carousel.data-object';

@Injectable()
export class CarouselService {
  _httpHandler: HttpHandlerService;
  _apiUrl = environment.apiUrl;
  _appTokenService: AppTokenService;
  _carouselEndPoint = 'v1/carousel';

  constructor(private httpHandler: HttpHandlerService, private appTokenService: AppTokenService) {
    this._httpHandler = httpHandler;
    this._appTokenService = appTokenService;
  }

  public GetItems(carouselObject): Observable<CarouselResponse> {
    const full_api_url = this._apiUrl + this._carouselEndPoint + carouselObject;
    const request_options = this._httpHandler.prepareRequest(
      'GET',
      full_api_url,
      {}
    );
    return this._httpHandler.request(request_options, this.appTokenService.tokenizedResponse);
  }
}
