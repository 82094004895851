
// Modules
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { HomeModule } from './modules/home/home.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from "ngx-spinner";
// Modules

// Components
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { FooterContentComponent } from './components/footer-content/footer-content.component';
import { SearchComponent } from './components/search/search.component';
// Components

// Services
import { CookieService } from 'ngx-cookie-service';
import { HttpHandlerService } from './services/http-handler.service';
import { TokenInterceptor } from './services/token.interceptor';
import { FooterService } from './services/footer.service';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { FormsModule } from '@angular/forms';
import { NavigationService } from './services/navigation.service';

// Services

// Pipes
import { SafeHtmlPipe } from './services/pipes/safe-html.pipe';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { Constants } from './modules/home';

// Pipes

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    FooterContentComponent,
    SearchComponent,
    SafeHtmlPipe,
    NotFoundComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      timeOut: 2000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      closeButton: true,
      maxOpened: 1
    }),
    NgxSpinnerModule
  ],
  providers: [
    CookieService,
    HttpHandlerService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    FooterService,
    ToastrService,
    NavigationService,
    Constants
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
