<div class="wrapper">
  <!-- Sidebar -->
  <nav id="sidebar">
    <div id="dismiss" #dismiss>
      <i class="fa fa-close" aria-hidden="true"></i>
    </div>    
    <div *ngIf="navigationData !== undefined">     
      <ul class="list-unstyled components">   
        <li *ngFor="let navigation of navigationData.data; let i = index">
            <a (click)="onNavigationClick(navigation, true);onHamburgerToggle(0)" [ngClass]="{ active: activePath === navigation.title }" href="javascript:void(0)">
                {{ navigation.label }}
            </a>
        </li>    
        <li *ngIf="isLogin">
          <a (click)="headerNavigation('/user/watchlist');onHamburgerToggle(0)">{{txtWatchList}}</a>
        </li>
        <li *ngIf="isLogin">
           <a (click)="headerNavigation('/user/favorites');onHamburgerToggle(0)">{{txtFavourites}}</a>
        </li>
      </ul>
    </div>
  </nav>
  <nav class="navbar navbar-fixed bg-black">
    <div class="container newHeader">
      <div class="headerLeft">
        <div class="menu-bar showOnMobile showOnTablet">
          <i class="fa fa-bars" id="sidebarCollapse"></i>
        </div>
        <div class="logo" (click)="goToHomePage()">
          <img src="{{ Global.IMAGE_PREFIX }}Dollywood-Play-logo.png" class="hideOnMobile" />
          <img src="{{ Global.IMAGE_PREFIX }}mobile_logo.png" class="showOnMobile" />
        </div>
        <div class="list-menu">
          <ul *ngIf="navigationData !== undefined">
            <li *ngFor="let navigation of navigationData.data; let i = index">
              <a (click)="onNavigationClick(navigation, true)" [ngClass]="{ active: activePath === navigation.title }" href="javascript:void(0)">
                {{ navigation.label }}
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="headerRight">
        <div class="attr-nav">
          <ul>

            <li class="genre_icon">
              <a style="color: #b1b1b1; cursor: pointer;" title="Genre" (click)="headerNavigation('/genres')">
                <img class="img-responsive" src="{{ Global.IMAGE_PREFIX }}genre_icon.png" style="display: inline-block">
              </a>
            </li>
            <li>
              <div class="language-drp dropdown">
                <button class="btn language-drp-btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                  aria-expanded="false">
                  {{selectedLang}}
                </button>
                <div class="drp-items dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a class="bb dropdown-item" (click)="setLanguage('en');">ENG</a>
                  <a class="bb-last dropdown-item" (click)="setLanguage('hi');">हिंदी</a>
                </div>
              </div>
            </li>
            <li class="search search-icon" id="search-box">
              <div class="pos_rel">
                <span class="search-icon-inside" (click)="enableSearch();">
                  <i class="fa fa-search" aria-hidden="true"></i>
                </span>

                <div class="search-box">
                  <form (formGroup)="searchForm">

                    <input (formControl)="searchTerm" *ngIf="searchEnabled" type="text" class="search" id="searchInput" #searchWeb placeholder="Search ..."
                      (keydown)="onKeydown($event, searchWeb.value)" (focusout)="focusOutFunction()" maxlength="128" />

                  </form>
                </div>
                <div id="autoSearchDrop" class="form-search-auto" *ngIf="searchRequest">
                  <div id="noResults" *ngIf="searchRequest && searchResults.length == 0">
                    {{pdTxtNoResult}}</div>
                  <div id="searchSuggest" *ngIf="searchResults.length != undefined || searchResults.length > 0">
                    <ul>
                      <li class="" *ngFor="let item of searchResults">
                        <a (click)="goToSearchPage(item.title, item.path)" href="javascript:void(0)">
                          <img src="{{item.verticalFilePath}}">
                          <p>{{item.title | slice:0:50}}
                            <span *ngIf="(item.title).length > 50">...</span>
                          </p>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <a id="viewMore" *ngIf="searchResults.length > 4" (click)="goToSearchPageViewMore()" class="text-center more_result">{{moreResults}}</a>
                </div>
              </div>
              <a href="/" style="display:none">
                <img src="./assets/images/search.png" class="img-responsive search_head_icon">
              </a>
            </li>
          </ul>
        </div>



        <div class="profile-box cursor dropdown">
          <img class="rounded-circle dropdown-toggle" src="{{ Global.IMAGE_PREFIX }}icon/profile.png" id="dropdownMenuButton" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false" />

          <div class="drp-items dropdown-menu" aria-labelledby="dropdownMenuButton">
            <div *ngIf="!isLogin; else LoggedIn">
              <a class="bb-last dropdown-item" (click)="headerNavigation('/auth/login')">{{pdSignIn}}</a>
            </div>
            <ng-template #LoggedIn>
              <a class="bb dropdown-item" (click)="headerNavigation('/user/favorites')">{{txtFavourites}}</a>
              <a class="bb dropdown-item" (click)="headerNavigation('/user/watchlist')">{{txtWatchList}}</a>
              <a class="bb dropdown-item" (click)="headerNavigation('/plans')">{{txtPlans}}</a>
              <a class="bb dropdown-item" (click)="headerNavigation('/user/orders')">{{txtOrders}}</a>
              <a class="bb dropdown-item" (click)="headerNavigation('/user/profile')">{{txtMyAccount}}</a>
              <a class="bb-last dropdown-item" (click)="showLogoffPopup()">{{mylogOut}}</a>
            </ng-template>
          </div>
        </div>
      </div>

      <div *ngIf="showSubscriptionPopup" class="js_close_logout_popup">
        <div class="js_logoutPopup logoutPopup">
          <span class="close_popup" (click)="closePopup()">
            <i class="fa fa-close"></i>
          </span>
          <span class="logoutText text-center"> {{msgSignOut}} </span>
          <br/>
          <div class="logoutSet">
            <a (click)="closePopup()" href="javascript:void(0);" class="logoutButton logYes">{{txtCancel}}</a>
            <a (click)="UserSignOut()" href="javascript:void(0);" class="logoutButton logYes">{{txtYes}}</a>
          </div>
          <div class="clearfix"></div>
        </div>
      </div>

      <!-- <div class="profile-box cursor" *ngIf="isLogin" (click)="GotoUserProfile()">
        <img *ngIf="isProfileImage != '' && isProfileImage != null" class="rounded-circle"
              [src]="isProfileImage"/>
        <img *ngIf="isProfileImage == '' || isProfileImage == null" class="rounded-circle"
              src="{{ Global.IMAGE_PREFIX }}blank-profile-picture-973460_640.png" />
      </div> -->
    </div>
    <div class="overlay"></div>
  </nav>
</div>