import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Constants, NavigationService, AppCoreService, MESSAGES_EN, MESSAGES_HI  } from './index';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  providers: [Constants]
})
export class SearchComponent implements OnInit {
  currentLanguage = 'en';
  _router: Router;

  searchPageResults: any[];
  searchCount: any = 0;
  pdTxtNoResult: any = '';
  pdTxtTotalResult: any = '';
  pdTxtSearchResult: any = '';
  pdBreadcrumbHome: any = '';
  nothingLoading: boolean = false;
  pdBreadcrumbSearch: any = '';
  searchText: string;
  skip: number;
  defaultImg: string;
  query = ''
  public searchSubscription: any;
  searchValue: string = "";
  watchNow: string;

  constructor(private activatedRoute: ActivatedRoute, private route: Router,
    private navigationService: NavigationService,
    private appCoreService: AppCoreService,
    private Global: Constants) {

    this.defaultImg = '/assets/images/default_image_horizontal.jpg';

  }


  ngOnInit(): void {
    this.appCoreService.setSetNavigationActive("");
    const emailmobile = this.appCoreService.GetUserEmailMobile();
    this.appCoreService.CheckLanguageSelected().subscribe(lang => {
      this.searchValue = this.route.url.substring(this.route.url.lastIndexOf('/') + 1);
      this.track_moeEvent(emailmobile);
      if (lang !== undefined) {
        // get selected language
        const selectedLanguage = this.appCoreService.GetLanguage();
        if (selectedLanguage !== undefined && selectedLanguage != null) {
          this.currentLanguage = selectedLanguage;
        }
        this.getSearchResults();

        this.pdBreadcrumbHome = MESSAGES_EN.breadcrumbHome;
        this.pdTxtNoResult = MESSAGES_EN.txtNoResult;
        this.pdTxtTotalResult = MESSAGES_EN.txtTotalResult;
        this.pdTxtSearchResult = MESSAGES_EN.txtSearchResult;
        this.pdBreadcrumbSearch = MESSAGES_EN.breadcrumbSearch;
        this.watchNow = MESSAGES_EN.watchNow;
        if (this.currentLanguage === 'hi') {
          this.pdBreadcrumbHome = MESSAGES_HI.breadcrumbHome;
          this.pdTxtNoResult = MESSAGES_HI.txtNoResult;
          this.pdTxtTotalResult = MESSAGES_HI.txtTotalResult;
          this.pdTxtSearchResult = MESSAGES_HI.txtSearchResult;
          this.pdTxtSearchResult = MESSAGES_HI.txtSearchResult;
          this.pdBreadcrumbSearch = MESSAGES_HI.breadcrumbSearch;
          this.watchNow = MESSAGES_HI.watchNow;                    
        }
      }
    });
  }

  getSearchResults(): void {
    const requestObject = {
      langId: this.currentLanguage === 'hi' ? 2 : 1,
      keyword: this.searchValue
    };
    const filterSearchString = '?filter=' + encodeURI(JSON.stringify(requestObject));
    this.navigationService.GetSearchResults(filterSearchString).subscribe(res => {
      //console.log(res);
      if (res !== undefined) {
        //console.log(res);
        this.searchPageResults = res.data.data;
        if (this.searchPageResults) {
          this.searchCount = this.searchPageResults.length;
        }
        this.nothingLoading = true;
      } else {
        this.nothingLoading = true;
      }
    });
  }


  redirectOnDetail(title, id): void {
    this.route.navigate(['/video', this.Global.GetTitleForUrl(title), id]);
  }
  
  track_moeEvent(emailmobile: any): void {
    if(emailmobile != undefined && emailmobile != null){
      var addMobile = emailmobile.match('^((\\+91-?)|0)?[0-9]{10}$');
    }
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (emailRegex.test(emailmobile)) {
      (window as any).Moengage.track_event('search_screen_opened', { "search_screen_opened": true, "email": emailmobile });
    }
   else if (addMobile != undefined && addMobile != "") {
      (window as any).Moengage.track_event('search_screen_opened', { "search_screen_opened": true, "mobile_number": emailmobile });
    }
    else{
      (window as any).Moengage.track_event('search_screen_opened', { "search_screen_opened": true });
    }
  }
}
