
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { AppTokenService } from './app-token.service';
import { catchError, map } from 'rxjs/operators';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(public auth: AppTokenService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token_data = this.auth.GetToken();
    //const token_data = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWNjZXNzIjp0cnVlLCJtZXNzYWdlIjoiIiwiZGF0YSI6ImV5SmhiR2NpT2lKSVV6STFOaUlzSW5SNWNDSTZJa3BYVkNKOS5leUpwWVhRaU9qRTJNRGszTlRjMU1qRXNJbWx6Y3lJNklpSXNJbTVpWmlJNk1Dd2laWGh3SWpveE5qUXhNamt6TlRJeExDSmtZWFJoSWpwN0ltbHpSM1ZsYzNRaU9uUnlkV1VzSW5WelpYSkpaQ0k2SWlJc0ltMXZZbWxzWlNJNklpSXNJbVZ0WVdsc0lqb2lJaXdpYm1GdFpTSTZJaUo5ZlEuSXRvRlRRM3ZsQjN1ZElhelUzbU9nUWhHcDBXU3BGaEhYY2JxZkZFa0FBcyIsImlhdCI6MTYwOTc1NzUyMX0.CiLB-wr4ygjcKGUtm1utb18hZ_sTbxh2GX_zQOkideg';
    
    // //console.log('interceptor');
    // //console.log(token_data);
    // request = request.clone({
    //   setHeaders: {
    //     Authorization: 'Bearer ' + token_data
    //   }
    // });
    request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token_data) });

    // //console.log(request);
    return next.handle(request).pipe(map((event: HttpEvent<any>) => {
        return event;
    }),
    catchError((error: any) => {
        //console.log(error);
        if (error.status === 0 || error.status === 401) {
          //console.log('ERROR - 404');
            // this.auth.removeToken();
        }
        return throwError(error);
    })
    );
  }
}
