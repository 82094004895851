import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SearchFire {
    private search = new BehaviorSubject<any>({});
    private searchText = new BehaviorSubject<string>('');

    getSearch() {
        return this.search.asObservable();
    }

    setSearch(searchResult) {
        this.search.next(searchResult);
    }

    getSearchText() {
        return this.searchText.asObservable();
    }

    setSearchText(searchText) {
        this.searchText.next(searchText);
    }
}
