import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHandlerService } from './http-handler.service';
import { environment } from './../../environments/environment';
import { AppTokenService } from './app-token.service';

@Injectable()
export class PageCategoryService {
  _httpHandler: HttpHandlerService;
  _apiUrl = environment.apiUrl;
  _appTokenService: AppTokenService;
  _pageCategoryEndPoint = 'v1/pagecategory';
  _pageCategoryDefaultListEndPoint = 'v1/pagecategory/listing';
  _pageCategoryDetailsEndPoint = 'v1/pagecategory/list';

  constructor(private httpHandler: HttpHandlerService, private appTokenService: AppTokenService) {
    this._httpHandler = httpHandler;
    this._appTokenService = appTokenService;
  }

  public GetPageCategories(pageCategoryObject): Observable<any> {
    const full_api_url = this._apiUrl + this._pageCategoryEndPoint + pageCategoryObject;
    const request_options = this._httpHandler.prepareRequest(
      'GET',
      full_api_url,
      {}
    );
    return this._httpHandler.request(request_options, this.appTokenService.tokenizedResponse);
  }

  public GetDefaultPageList(pageCategoryListingObject): Observable<any> {
    const full_api_url = this._apiUrl + this._pageCategoryDefaultListEndPoint + pageCategoryListingObject;
    const request_options = this._httpHandler.prepareRequest(
      'GET',
      full_api_url,
      {}
    );
    return this._httpHandler.request(request_options, this.appTokenService.tokenizedResponse);

  }

  public GetPageCategoryDetails(pageCategoryObject): Observable<any> {
    const full_api_url = this._apiUrl + this._pageCategoryDetailsEndPoint + pageCategoryObject;
    const request_options = this._httpHandler.prepareRequest(
      'GET',
      full_api_url,
      {}
    );
    return this._httpHandler.request(request_options, this.appTokenService.tokenizedResponse);
  }
}
