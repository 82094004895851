<ngx-spinner type="ball-spin" size="medium" bdColor="rgba(0, 0, 0, 1)" color="#e95f05">
</ngx-spinner>
<ng-container *ngIf="!hideElement && router.url !== '/auth/login' && router.url.split('?')[0] !== '/auth/login'">
  <div class="comp-wrapper-header">
    <app-header *ngIf="isLang"></app-header>
  </div>
</ng-container>
<router-outlet></router-outlet>
<ng-container *ngIf="!hideElement && router.url !== '/auth/login' && router.url.split('?')[0] !== '/auth/login'">
  <app-footer *ngIf="isLang"></app-footer>
</ng-container>
