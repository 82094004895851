export class NavigationDataObject {
    data: NavigationItem[];
}

export class NavigationItem {
    path: string;
    title: string;
    sameForAllZone: number;
    sameForAllProductType: number;
    exclusionText: string;
    label: string;
    description: string;
    mapWithHeaderOrBurgerMenu: string;
    isCarouselApplicable: number;
    displayIndexing: number;
    primaryNavigationType: string;
    fileName: string;
    filePath: string;
    type: string;
    menuCategory: [];
}
