import { AppTokenService } from './app-token.service';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHandlerService } from './http-handler.service';
import { environment } from './../../environments/environment';

@Injectable()
export class NavigationService {
  _httpHandler: HttpHandlerService;
  _apiUrl = environment.apiUrl;
  _apiEndPoint = 'v1/navigation';
  _searchEndPoint = 'v1/search';

  constructor(private httpHandler: HttpHandlerService, private appTokenService: AppTokenService) {
    this._httpHandler = httpHandler;
  }

  public Get(navigationDataObject): Observable<any> {
    const filter =  encodeURI(JSON.stringify(navigationDataObject));
    const navigation_api = this._apiUrl + this._apiEndPoint;
    const request_options = this._httpHandler.prepareRequest(
      'GET',
      navigation_api + '?filter=' + filter,
      {},
      // token
    );

    return this._httpHandler.request(request_options, this.appTokenService.tokenizedResponse);
  }

  public GetSearchResults(searchObject): Observable<any> {
    const full_api_url = this._apiUrl + this._searchEndPoint + searchObject;
    const request_options = this._httpHandler.prepareRequest(
      'GET',
      full_api_url,
      {}
    );
    return this._httpHandler.request(request_options, this.appTokenService.tokenizedResponse);

  }
}
