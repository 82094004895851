import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import {AppCoreService, FooterService, FooterDataObject, MESSAGES_EN, MESSAGES_HI, Constants} from './index';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  providers: [ Constants ]
})
export class FooterComponent implements OnInit {
  _appCoreService: AppCoreService;
  _cookieService: CookieService;
  _footerService: FooterService;
  _router: Router;
  footerData: FooterDataObject;
  disclaimerFlag = '';
  footerLinksData: any;
  footerLinks: any;
  year: any = new Date().getFullYear();
  pdTxtContactUs = '';
  pdTxtClickAndFind = '';
  pdTxtAllRightsReserved = '';
  appStoreFlag = true;
  selected_language = 'en';

  constructor(
    private appCoreService: AppCoreService,
    cookieService: CookieService,
    footerService: FooterService,
    router: Router,
    public Global: Constants
    ) {
    this._router = router;
    this._appCoreService = appCoreService;
    this._cookieService = cookieService;
    this._footerService = footerService;
  }

  ngOnInit(): void {
    this.disclaimerFlag = this._cookieService.get('readDisclaimer');    
    this.appCoreService.CheckLanguageSelected().subscribe(lang => {     
      this.pdTxtContactUs = MESSAGES_EN.txtContactUs;
      this.pdTxtClickAndFind = MESSAGES_EN.txtClickAndFind;
      this.pdTxtAllRightsReserved = MESSAGES_EN.txtAllRightsReserved;
      this.selected_language = this.appCoreService.GetLanguage();
      if (this.selected_language === this.Global.LANG_HI) {
        this.pdTxtContactUs = MESSAGES_HI.txtContactUs;
        this.pdTxtClickAndFind = MESSAGES_HI.txtClickAndFind;
        this.pdTxtAllRightsReserved = MESSAGES_HI.txtAllRightsReserved;
      }
      this.getFooterLinksData();
    });

  }

  public getFooterGenres(): void {
    this._footerService.GetFooterGenres().subscribe(
      res => {
        if (res !== undefined && res.success === true) {
          this.footerData = res;
        }
      },
      err => {
        //console.log(err);
      }
    );

  }

  /*
  public sendContact(contactform: FormGroup): void {
    this.is_loading = true;
    const pageRequest = {
      fullName: contactform.value['name'],
      email: contactform.value['email'],
      mobile: contactform.value['mobile'],
      message: contactform.value['massage']
    };
    this._footerService.sendContactUs(pageRequest).subscribe(
      res => {
        if (res !== undefined && res.success == true) {
          this.successResponse(res.message);
          this.contactUsForm.reset();
          $('#contact-form').modal('hide');
          this.is_loading = false;
        } else if (res !== undefined && res.success == false) {
          this.errMessageResponse(res.message);
          this.is_loading = false;
        }
      },
      err => {
        //console.log(err);
      }
    );
  }
  */

  public getFooterLinksData(): void {
    const footerRequest = {
      deviceTypeId: this.Global.WEB_DEVICE_ID,
      langId: this.selected_language === this.Global.LANG_HI ? 2 : 1
    };
    const filterString = '?filter=' + encodeURI(JSON.stringify(footerRequest));
    this._footerService.GetFooterLinks(filterString).subscribe(
      res => {
        if (res !== undefined) {
          this.footerLinksData = res;
          this.footerLinks = res.data.data;
        }
      },
      err => {
        //console.log(err);
      }
    );
  }

  public goToFooterLinkData(footerlink: string, path: string): void {

    window.open(window.location.origin + '/footer' + '/' + footerlink.split(' ').join('').toLowerCase() + '/' + path, '_blank');
  }

  public openGenresListing(data): void {
    this._router.navigate(['/category', data.title.split(/\s/).join('').toLowerCase(), data.path]);
  }

  setcookie(): void {
    this._cookieService.set('readDisclaimer', 'true' );
    this.disclaimerFlag = this._cookieService.get('readDisclaimer');
  }

  closeAppStore(): void {
    this.appStoreFlag = false;
  }
}
