export class FooterDataObject {
    success: boolean;
    message: string;
    data: FooterDataList[];
    iat: any;
}

export class FooterDataList {
    totalCount: number;
    pageCount: number;
    data: FooterOrientation[];
}

export class FooterOrientation {
    path: number;
    title: string;
    description: string;
    isActive: any;
    modified: any;
    verticalFileName: string;
    verticalFilePath: string;
    horizontalFileName: string;
    horizontalFilePath: string;
    dtypeId: any;
    langId: any;
    confTitle: string;
    exclusionText: string;
    sameForAllZone: any;
    sameForAllProductType: any;
    label: string;
    confDescription: any;
    isVisible: any;
    seoTitle: string;
    seoKeyword: string;
    seoDescription: string;
    type: string;
}
