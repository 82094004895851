// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  imagePrefix: 'assets/images/',
  //apiUrl: 'https://api-dp.dctinc.net/',
  apiUrl: 'https://iao2ttozd0.execute-api.us-east-1.amazonaws.com/stage/',
  //apiUrl: 'https://nrw29mudv4.execute-api.ap-south-1.amazonaws.com/prd/',
  // apiUrl: 'https://nrw29mudv4.execute-api.ap-south-1.amazonaws.com/prd/',
  // apiUrl: 'https://vuuzleapi.dcafecms.com/',
  apiTokenizationEnabled: true,
  // razorPayKey: 'rzp_test_S970OjhmJTmgd4'
  razorPayKey: 'rzp_test_4U6Nk74jCugcTW'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
