import { FooterService } from './../../services/footer.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { AppCoreService } from 'src/app/services/app-core.service';

@Component({
  selector: 'app-footer-content',
  templateUrl: './footer-content.component.html',
  styleUrls: ['./footer-content.component.scss']
})
export class FooterContentComponent implements OnInit {

  linkContent: any;
  emailmobile: any;

  constructor(private footerService: FooterService, private activatedRoute: ActivatedRoute, private appCoreService: AppCoreService) {

   }

  ngOnInit(): void {
    this.emailmobile = this.appCoreService.GetUserEmailMobile();
    this.getFooterLinksContentData();
    this.track_moeEvent(this.emailmobile);
  }

  public getFooterLinksContentData(): void {
    let input_path;
    const param_path = 'path';
    this.activatedRoute.params.subscribe((params: Params) => {
      input_path = params[param_path];
    });
    const pageRequest = {
      path: input_path,
      deviceTypeId: '1',
      langId: '1'
    };

    const fiterString = '?filter=' + encodeURI(JSON.stringify(pageRequest));
    //console.log(pageRequest); //console.log(input_path);
    this.footerService.GetFooterLinkContent(input_path, fiterString).subscribe(
      (res: any) => {
        if (res !== undefined) {
          //console.log(res.data[0]);
          if (res.data[0] !== undefined) {
            this.linkContent = res.data[0].description;
          }
          // this.footerLinkContentData = res.data[0];
          // if (this.footerLinkContentData) {
          //   this.footerLinkContent = res.data[0];
          //   document.getElementById('html').insertAdjacentHTML('beforeend', this.footerLinkContent.description);
          // }
        }
      },
      err => {
        //console.log(err);
      }
    );

  }

  track_moeEvent(emailMobile: any): void {
    if(emailMobile != undefined && emailMobile != null){
      var addMobile = emailMobile.match('^((\\+91-?)|0)?[0-9]{10}$');
    }
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (emailRegex.test(emailMobile)) {
        (window as any).Moengage.track_event('webview_screen_opened', { "webview_screen_opened": true, "email": emailMobile });
      }
      else if (addMobile != undefined && addMobile != "") {
        (window as any).Moengage.track_event('webview_screen_opened', { "webview_screen_opened": true, "mobile_number": emailMobile });
      }
      else
      {
        (window as any).Moengage.track_event('webview_screen_opened', { "webview_screen_opened": true });
      }  
  } 
}
