<footer>
    <div class="d_footer">
        <div class="container">

            <ul class="footer_ul">
                <li class="footer_social_li">
                    <a href="https://www.facebook.com/Dollywood-Play-727257140761346/?ref=br_rs" target="_blank">
                        <span class="fa-stack fa-lg ">
                            <i class="fa fa-facebook-f fa-stack-1x"></i>
                        </span>
                    </a>
                </li>
                <li class="footer_social_li">
                    <a href="https://twitter.com/dollywood_play" target="_blank">
                        <span class="fa-stack fa-lg ">
                            <i class="fa fa-twitter fa-stack-1x"></i>
                        </span>
                    </a>
                </li>

                <li class="footer_social_li">
                    <a href="https://instagram.com/dollywood_play?utm_source=ig_profile_share&igshid=lyo2mwy27u85" target="_blank">
                        <span class="fa-stack fa-lg ">
                            <i class="fa fa-instagram fa-stack-1x"></i>
                        </span>
                    </a>
                </li>

            </ul>
            <div class="row">
                <div class="col-md-6 col-sm-6 col-xs-6 col-6">
                    <div class="footer-links border-right-footer text-right">
                        <ul>
                            <li *ngFor="let item of footerLinks" (click)="goToFooterLinkData(item.title, item.path)">
                                <a href="javascript:void(0)">
                                    {{item.title}} 
                                </a>
                            </li>
                            <li>
                                <a target="_blank" routerLink='/contact-us'>{{pdTxtContactUs}}</a>
                            </li>
    
                        </ul>
                    </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-6 col-xs-6 col-6">
                    <div class="footer-right-content text-left storeSection">
                        <img alt="Home" class="logo" src="{{Global.IMAGE_PREFIX}}Dollywood-Play-logo.png" width="200px">
                        <div class="clearfix" style="height:10px;"></div>
    
                        <span>{{pdTxtClickAndFind}}</span>
                        <div class="clearfix" style="height:10px;"></div>
    
                        <ul class="footer_ul play-store-links">
                            <li class=""></li>
                            <li class="footer_li ">
                                <a href="https://itunes.apple.com/us/app/dollywood-play/id1266574888?mt=8" target="_blank">
                                    <img src="{{Global.IMAGE_PREFIX}}appStore.png" />
                                </a>
                            </li>
                            <li class="footer_li">
                                <a target="_blank" href="https://play.google.com/store/apps/details?id=com.wamindia.dollywoodplay">
                                    <img src="{{Global.IMAGE_PREFIX}}goglePlay.png" />
                                </a>
                            </li>
    
                        </ul>
                    </div>
                </div>
            </div>

        </div>
        <div class="m_textcenter copyright_text" style="">
            © Dollywood Play {{year}} {{pdTxtAllRightsReserved}}
        </div>

    </div>

    <div class="footericon visible-xs-block" *ngIf="appStoreFlag">
        <div style="background:#242424;position:fixed;bottom:0px;width:100%;padding:5px 0px 10px 0;z-index:99999999;color:#fff;">
            <a class="close-btn" type="button" (click)="closeAppStore()">X</a>
            <p class="text-center">Download on Android & iOS</p>
            <div class="row">
                <div class="col-xs-6 col-6 text-right">
                    <a href="https://itunes.apple.com/us/app/dollywood-play/id1266574888?mt=8" target="_blank">

                        <img src="{{Global.IMAGE_PREFIX}}appStore.png" />
                    </a>
                </div>
                <div class="col-xs-6 col-6 text-left">
                    <a target="_blank" href="https://play.google.com/store/apps/details?id=com.wamindia.dollywoodplay">
                        <img src="{{Global.IMAGE_PREFIX}}goglePlay.png" />
                    </a>
                </div>
            </div>


        </div>
    </div>


</footer>

<!-- End footer -->


<div *ngIf="disclaimerFlag == ''" class="cookies-set">
    <div class="col-md-10" style="float:left;margin-top:10px;">
        This website uses cookies to ensure you get the best experience on our website.
        <a target="_blank" href="footer/PrivacyPolicy/1">Learn more</a>
    </div>
    <div class="col-md-2" style="float:right">
        <button id="gotit_submit" class="btn btn-lg btn-primary" type="button" style="float:right" (click)="setcookie()">Got it</button>
    </div>
</div>