import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';

import { AppCoreService } from './services/app-core.service';
import { NgxSpinnerService } from "ngx-spinner";
import { filter } from 'rxjs/operators';
import { Constants } from './modules/home';
declare var gtag;
declare var $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [Constants]
})
export class AppComponent implements OnInit {
  title = 'dcafe-web';
  hideElement = false;
  isLang = true;

  constructor(
    public router: Router,
    private appCoreService: AppCoreService,
    private spinner: NgxSpinnerService,
    private Global: Constants
    ) {    
    if (this.appCoreService.getUserLoggedInStatus() && !this.appCoreService.GetLanguage()){
      router.navigate(['home']);
    }
    //--google analytics--//
    const navEndEvents = router.events.pipe(
      filter(e => e instanceof NavigationEnd)
    );
    navEndEvents.subscribe((event: NavigationEnd) => {
      gtag('config', 'UA-88199622-1', { 'page_path': event.urlAfterRedirects });
    });
  }
  ngOnInit(): void {    
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {    
        // if the url path starts with 'video' and parameter length is 2, set/override default lang 'en'. 
        // this.appCoreService.getUserLoggedInStatus() &&  lang. already set
        if(!this.appCoreService.GetLanguage() && event.url.split('/video').length === 2){             
          this.appCoreService.SetLanguage(this.Global.LANG_EN);      
          return;
        }else{
          // do not show loader
          if (event.url === '/language') {                  
            this.isLang =false;
          } else{
            this.checkLanguageSelected();
            this.spinner.show();
          }          
        }
      } else if (event instanceof NavigationEnd) {
          setTimeout(() => { this.spinner.hide(); }, 100);
      }
    });    
  }

  checkLanguageSelected(): void {

    this.appCoreService.CheckLanguageSelected().subscribe(res => {
      if (res !== undefined) {
        if (res === false) {          
          this.hideElement = true;
          this.router.navigate(['/language']);
        } else {
          this.hideElement = false;
        }
      }
    });
  }

  getToken(): void {
    //console.log('get token');
  }
}
