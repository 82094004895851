
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpHandlerService } from './http-handler.service';
import { environment } from './../../environments/environment';
import { TokenData } from './../models/user.data-object';

@Injectable({
  providedIn: 'root'
})
export class AppTokenService {
  // _httpHandler: HttpHandlerService;
  _apiUrl = environment.apiUrl;
  _guestTokenEndPoint = 'v1/token/guest';
  _userTokenEndPoint = 'v1/token/user';
  tokenData = new BehaviorSubject<TokenData>(new TokenData());
  isTokenExist = new BehaviorSubject<boolean>(this.hasToken());
  private internal_token_data: TokenData;
  tokenizedResponse = environment.apiTokenizationEnabled;

  constructor(private httpHandler: HttpHandlerService) {

  }

  // Guest Tokens
  private setGuestToken(token: string): void {
    localStorage.setItem('guest_token', token);
  }

  private getGuestToken(): string {
    return localStorage.getItem('guest_token');
  }
  private hasGuestToken(): boolean {
    return !!localStorage.getItem('guest_token');
  }

  // User Tokens
  private setUserToken(token: string): void {
    localStorage.setItem('user_token', token);
  }

  private getUserToken(): string {
    return localStorage.getItem('user_token');
  }

  private hasUserToken(): boolean {
    return !!localStorage.getItem('user_token');
  }

  private hasToken(): boolean {
    const saved_token = !!localStorage.getItem('token');
    if (saved_token) {
      const tokenData: TokenData = JSON.parse(localStorage.getItem('token'));
      if (tokenData.token !== '') {
        return true;
      }
    }
    return false;
  }

  public CheckToken(): Observable<boolean> {
    return this.isTokenExist.asObservable();
  }

  // remove the token - usually after user logout
  public removeToken(): void {
    localStorage.removeItem('token');
    const _tokenData = new TokenData();
    _tokenData.token = '';
    _tokenData.type = '';
    this.tokenData.next(_tokenData);
    this.isTokenExist.next(false);
  }

  // Abstract setter and getter token methods
  public SetToken(value, type = 'guest'): void {
    const _tokenData = new TokenData();
    _tokenData.token = value;
    _tokenData.type = type;
    localStorage.setItem('token', JSON.stringify(_tokenData));
    // if (type === 'guest')
    // {

    // } else if (type === 'user') {
    //   localStorage.setItem('token', JSON.stringify(_tokenData));
    // }
    //console.log('set token');
    this.tokenData.next(_tokenData);
    this.isTokenExist.next(true);
  }

  public GetToken(): string {
    const tokenData: TokenData = JSON.parse(localStorage.getItem('token'));
    return tokenData.token;
  }
  // get the token either from the local storage or generates a new one
  // public GenerateToken(): Observable<any> {
  //   //console.log('Get Token');
  //   //console.log('calling GenerateToken');
  //   // generate a new guest token
  //   this.GetToken().subscribe(res => {
  //     if (res !== undefined) {
  //       //console.log(res);
  //       this.SetToken(res);
  //     }
  //   });

  //   return this.tokenData.asObservable();
  // }

  // public GenerateToken(): void {
  //   //console.log('Get Token');
  //   // check if user token exists
  //   if (this.hasUserToken()) {

  //   } else if (this.hasGuestToken()) {

  //   } else {
  //     //console.log('calling GenerateToken');
  //     // generate a new guest token
  //     this.GetToken().subscribe(res => {
  //       if (res !== undefined) {
  //         //console.log(res);
  //         this.SetToken(res);
  //       }
  //     });
  //   }
  //   // return this.tokenData.asObservable();
  // }

  GetTokenData(): string {
    //console.log(this.tokenData);
    //console.log(this.tokenData.value);
    this.internal_token_data = this.tokenData.value;
    return this.internal_token_data.token;
  }

  // Token methods
  public CreateToken(isGuestToken = true): Observable<any> {
    let request_options;
    if (isGuestToken)
    {
      // if the token request is made for Guest
      const full_api_url = this._apiUrl + this._guestTokenEndPoint;
      request_options = this.httpHandler.prepareRequest(
        'GET',
        full_api_url,
        {}
      );
      return this.httpHandler.request(request_options, false);
    }
    else
    {
      // if the token request is made for User
      const full_api_url = this._apiUrl + this._userTokenEndPoint;
      request_options = this.httpHandler.prepareRequest(
        'GET',
        full_api_url,
        {}
      );
      return this.httpHandler.request(request_options);
    }
  }
}
