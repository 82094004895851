<div class="container-fluid">
    <div id="block-dollywood-content" class="margin_top_prinav min_page_height">
        <div class="container-fluid min_page_height">
            <div class="container">
                <p class="season_path season_path_top100"><span><a routerLink="/">{{pdBreadcrumbHome}}</a></span> <span>
                        | </span> <span>{{pdBreadcrumbSearch}}</span></p>
                <div id="data">
                    <div class="row title-bar">

                        <div class="shows-title row p-0" style="float:left;width:100%">

                          <div class="col-sm-6 col-md-6 col-lg-6">
                            <div id="notResultFound" style="display: none;"></div>
                            <!-- <div id="notResultLeft">{{pdTxtSearchResult}} '<span id="search_strong">{{searchValue}}</span>'</div>-->
                          <div id="notResultLeft" *ngIf="currentLanguage === 'hi'">
                            '<span id="search_strong">{{searchValue}}</span>' {{pdTxtSearchResult}}
                          </div>
                            <div id="notResultLeft" *ngIf="currentLanguage === 'en'">
                              {{pdTxtSearchResult}} '<span id="search_strong">{{searchValue}}</span>'
                            </div>
                          </div>
                            <div class="col-sm-6 col-md-6 col-lg-6">
                                <div id="notResultRight">
                                    <div class="pull-right">{{pdTxtTotalResult}} : <span
                                            id="TotalCount">{{searchCount}}</span></div>
                                </div> <br>
                            </div>

                        </div>
                    </div>
                    <div class="clearfix"></div>
                    <div class="text-center" *ngIf="searchCount == 0">{{pdTxtNoResult}}</div>

                    <div id="innerSearch" class="search_content" style="margin-top:25px;">
                        <span class="views-field views-field-field-map-with-video-pag-cat inner-carousel">
                            <span class="field-content">
                                <div class="col-xs-6 col-6 col-sm-3 col-md-2 margin30 padd_7 d-inline-block thumbNewDesgin"
                                    *ngFor="let item of searchPageResults">
                                    
                                        <!-- <a *ngIf="item.type=='asset'" routerLink="/video/{{item.orientation[0].title.trim().toLowerCase()|titleurl}}/{{item.path}}" > -->
                                        <a *ngIf="item.type=='asset'" (click)="redirectOnDetail(item.title, item.path)">
                                            <div class="vidWrapper">
                                            <!-- <img class="img_vertical"  src="{{item.orientation[0].image == undefined || item.orientation[0].image.length == 0 ? defaultAssetImage :  item.orientation[0].image}}"  src-fallback="{{ defaultImg }}" style="display: inline;" width="100%;">--> 
                                            <img class="img_vertical" src="{{item.verticalFilePath}}"
                                                src-fallback="{{ defaultImg }}" style="display: inline;" width="100%;">
                                            <div class="content">

                                            <div class="desc">{{item.description}}</div>
                                                <div class="watchnowbutton" style="margin-top:5px;">
                                                    {{watchNow}}
                                                </div>
                                            </div>
                                        </div>
                                        </a>
                                    
                                    <div class="corousel-item-footer-wrapper">
                                        <div class="corousel-item-footer">
                                            <h5 class="hidden-md hidden-lg hidden-sm"><b>{{item.title|slice:0:30}}<span
                                                        *ngIf="(item.title).length > 30">...</span></b></h5>
                                            <!--<h5 class="hidden-xs" title="{{item.title}}">
                                                <b>{{item.title|slice:0:40}}<span
                                                        *ngIf="(item.title).length > 40">...</span></b></h5>-->
                                            <!-- <div class="statistic"> -->
                                                <!-- <div class="duration">{{item.duration | ShowHrsMinModule }} </div>-->
                                                <!-- <div class="likes"><i class="fa fa-thumbs-up"></i>{{item.likes_count}}
                                                </div> -->
                                                <!-- <div class="views"><i class="fa fa-eye"></i>{{item.watched_count}}</div> -->
                                            <!-- </div> -->
                                        </div>
                                    </div>
                                </div>
                            </span>
                        </span>
                    </div>

                    <div class="clearfix"></div><br>
                </div>
            </div>
        </div>
    </div>
</div>

